// BottomNavigation.js
import { connect } from 'react-redux';

import {
  LIVESTREAM_FEED,
  LIVESTREAM_FEED_SORTINGS,
  SHORTS_FEED_ALIAS,
  LIVESTREAM_CLIP_FEED_ALIAS,
  BROWSE_TABS,
} from '../RemoteConfigKeys.js';

import login from '../action/login.js';
import fetchAccountLinks from '../action/fetchAccountLinks.js';
import { fetchUnreadChatrooms } from '../action/fetchChatrooms.js';
import openUploadMenuModal from '../action/openUploadMenuModal.js';
import fetchFeeds from '../action/fetchFeeds.js';
import subscribeFeedChannel from '../action/subscribeFeedChannel.js';
import unsubscribeFeedChannel from '../action/unsubscribeFeedChannel.js';

import getMeData from '../selector/getMeData.js';
import getNetworkingData from '../selector/getNetworkingData.js';
import getHasUnsetItemOnManageProfile from '../selector/getHasUnsetItemOnManageProfile.js';
import getModalData from '../selector/getModalData.js';
import getRemoteConfigData from '../selector/getRemoteConfigData.js';
import { getSelectedQueryOptionObject } from '../selector/getFeedQueryObject.js';
import getSelectedFeedQueryOptions from '../selector/getSelectedFeedQueryOptions.js';
import getFeedRenewData from '../selector/getFeedRenewData.js';

import BottomNavigation from '../component/BottomNavigation.jsx';
import getOperationData from '../selector/getOperationData.js';
import addModal from '../action/addModal.js';
import removeModals from '../action/removeModals.js';

const mapStateToProps = state => {
  const apiCacheTimestamp = getOperationData(
    state,
    ['chat'],
    'apiCacheTimestamp'
  );
  const livestreamFeedName = getRemoteConfigData(state, LIVESTREAM_FEED);
  const { intersectedFeedName, feedNameWithQuery } =
    getSelectedQueryOptionObject(
      state,
      livestreamFeedName,
      getSelectedFeedQueryOptions(state, LIVESTREAM_FEED, undefined),
      getRemoteConfigData(state, LIVESTREAM_FEED_SORTINGS)?.[0]
    );
  return {
    hasUnread: getMeData(state, 'hasUnread'),
    hasUnsetItemOnManageProfile: getHasUnsetItemOnManageProfile(state),
    isAccountLinksFetching: getNetworkingData(
      state,
      ['me', 'accountLinks'],
      'isFetching'
    ),
    isAccountLinksFetched: getNetworkingData(
      state,
      ['me', 'accountLinks'],
      'isFetched'
    ),
    isAuthed: !!getMeData(state, 'id'),
    apiCacheTimestamp,
    isHamburgerMenuOpen:
      getModalData(state, 'HamburgerMenuModal', 'status') === 'display',
    isShortEnabled: !!getRemoteConfigData(state, SHORTS_FEED_ALIAS),
    renewFeedId: getFeedRenewData(state, intersectedFeedName, 'id'),
    feedNameWithQuery,
    browseTabs: getRemoteConfigData(state, BROWSE_TABS),
    latestFeedRenewUnix: getFeedRenewData(
      state,
      intersectedFeedName,
      'timestamp'
    ),
    clipCategory: getRemoteConfigData(state, LIVESTREAM_CLIP_FEED_ALIAS),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    login: () => dispatch(login()),
    fetchAccountLinks: () => dispatch(fetchAccountLinks()),
    fetchUnreadChatrooms: data => dispatch(fetchUnreadChatrooms(data)),
    openUploadMenuModal: () => dispatch(openUploadMenuModal()),
    openHamburgerMenu: () =>
      dispatch(
        addModal({
          id: 'HamburgerMenuModal',
          isHigherThanAll: true,
        })
      ),
    closeHamburgerMenu: () =>
      dispatch(
        removeModals({
          ids: ['HamburgerMenuModal'],
          transitionStatus: 'closing',
        })
      ),
    fetchFeeds: payload => dispatch(fetchFeeds(payload)),
    subscribeFeedChannel: data => dispatch(subscribeFeedChannel(data)),
    unsubscribeFeedChannel: data => dispatch(unsubscribeFeedChannel(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BottomNavigation);
