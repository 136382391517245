// getChatroomData.js
'use strict';
import { Collection, fromJS } from 'immutable';
import createCachedSelector from '../resource/createCachedSelector.js';

const removeInvalidSeparator = dataKey => dataKey.replace(/^\.|\.$/g, '');
/**
 * Select chatroom data by id
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @param {string} id - chatroom id.
 * @param {string|Array} dataKey - select key.
 * @param {boolean} [filterMe = false] - a flag to filter me from the participants
 * @return {any} The selected chatroom data.
 */
const getChatroomData = createCachedSelector(
  (state, id) => state.getIn(['chatrooms', id]),
  (state, id, dataKey) =>
    Array.isArray(dataKey)
      ? dataKey
      : removeInvalidSeparator(dataKey).split('.'),
  (state, id, dataKey, filterMe = false) => filterMe,
  (state, id, dataKey, filterMe = false) =>
    filterMe ? state.getIn(['me', 'id']) : undefined,
  (chatroom, joinKey, filterMe, meId) => {
    let result = undefined;

    if (chatroom != null) {
      const participantIds = chatroom.get('participantIds') || fromJS([]);
      const data = filterMe
        ? chatroom.set(
            'participantIds',
            participantIds.filter(userId => userId !== meId)
          )
        : chatroom;

      result = data.getIn([...joinKey]);
    }

    return result instanceof Collection ? result.toJS() : result;
  }
)((state, id, dataKey, filterMe) => {
  const key = Array.isArray(dataKey) ? dataKey.join('.') : dataKey;
  return `${id}:${key}:${filterMe}`;
});

export default getChatroomData;
