// getHasUnsetItemOnManageProfile.js
'use strict';
import {
  JWT_SCOPE_CREATOR,
  JWT_SCOPE_SUBSCRIBABLE,
} from '../RemoteConfigKeys.js';

import { createSelector } from '../resource/createCachedSelector.js';

import getIsPermissionMatched from '../selector/getIsPermissionMatched.js';
import getMeData from '../selector/getMeData.js';
import getShouldShowPasswordBindingHint from '../selector/getShouldShowPasswordBindingHint.js';
import getIsCpOnboarding from '../selector/getIsCpOnboarding.js';

/**
 * Get has unset items on manage profile
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @return {boolean} Return true if there is unset item on manage profile.
 */
const getHasUnsetItemOnManageProfile = createSelector(
  state => getShouldShowReplyPriceHint(state),
  state => getShouldShowPasswordBindingHint(state),
  state => getShouldShowPayoutMethodHint(state),
  (
    shouldShowReplyPriceHint,
    shouldShowPasswordBindingHint,
    shouldShowPayoutMethodHint
  ) => {
    return (
      shouldShowReplyPriceHint ||
      shouldShowPayoutMethodHint ||
      shouldShowPasswordBindingHint
    );
  }
);

/**
 * Get should show reply price hint
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @return {boolean} Return true if reply price not has been set.
 */
export const getShouldShowReplyPriceHint = createSelector(
  state => getIsPermissionMatched(state, JWT_SCOPE_CREATOR),
  state => getIsCpOnboarding(state),
  state => getMeData(state, 'replyPrice'),
  (isCP, isCpOnboarding, meReplyPrice) => {
    if (!isCP && !isCpOnboarding) {
      return false;
    }
    return meReplyPrice == null;
  }
);

/**
 * Get should show subscription price hint
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @return {boolean} Return true if subscription price not has been set.
 */
export const getShouldShowSubscriptionPriceHint = createSelector(
  state => getIsPermissionMatched(state, JWT_SCOPE_SUBSCRIBABLE),
  state => getMeData(state, 'subscriptionPlan')?.amount,
  (isSubscribable, meSubscriptionPrice) => {
    if (!isSubscribable) {
      return false;
    }
    return meSubscriptionPrice == null;
  }
);

/**
 * Get should show payout method hint
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @return {boolean} Return true if payout method not has been set.
 */
export const getShouldShowPayoutMethodHint = createSelector(
  state => getIsPermissionMatched(state, JWT_SCOPE_CREATOR),
  state => getIsCpOnboarding(state),
  state => !!getMeData(state, 'bankInfo'),
  (isCP, isCpOnboarding, isPayoutMethodSet) => {
    if (!isCP && !isCpOnboarding) {
      return false;
    }
    return !isPayoutMethodSet;
  }
);

export default getHasUnsetItemOnManageProfile;
