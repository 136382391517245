// fetchChatrooms.js
'use strict';
import parseLinkHeader from 'parse-link-header';

import fetch from '../resource/customFetch.js';
import { throttle, debounce } from 'lodash';
import handleFetchError from '../resource/handleFetchError.js';
import { objectifyArrayById } from '../resource/objectifyArrayById.js';
import getResourceUrl from '../resource/getResourceUrl.js';
import { getHeaders } from '../resource/fetchOptionHeader.js';
import fetchUser from '../action/fetchUser.js';
import getMeData from '../selector/getMeData.js';
import getOperationData from '../selector/getOperationData.js';
import {
  ADD_CHATROOMS,
  ADD_USERS,
  ADD_MESSAGES,
  ADD_LIST_ITEMS,
  SET_LIST_ITEMS,
  SET_NETWORKING_FETCHING,
  SET_NETWORKING_SUCCESS,
  SET_NETWORKING_ERROR,
  MERGE_ME_DATA,
} from '../ActionTypes.js';
import { chatroomListType } from '../resource/chatroomConstants.js';
import getChatroomData from '../selector/getChatroomData.js';

const API_THROTTLE_SECONDS = 8; // TODO: remote config
let throttleFunctionMap = {};

const UNREAD_DEBOUNCE_MSEC = 200; // TODO: remote config
let debounceFunction = undefined;

/**
 * Fetch chatrooms
 * @kind action
 * @param {Object} params - parameters
 * @param {number} [params.page = 1] - the page number (optional).
 * @param {number} [params.limit = 10] - the page size (optional).
 * @param {string} [params.listType] - chatroom list type.
 * @return {Promise} Action promise.
 */

export const fetchChatrooms =
  ({ page = 1, limit = 20, listType = chatroomListType.ALL } = {}) =>
  async dispatch => {
    if (!throttleFunctionMap[listType]) {
      throttleFunctionMap[listType] = throttle(
        ({ page, limit, listType }) =>
          dispatch(fetchChatroomsCore({ page, limit, listType })),
        API_THROTTLE_SECONDS * 1000
      );
    }
    return throttleFunctionMap[listType]({ page, limit, listType });
  };

export const fetchUnreadChatrooms =
  ({ page = 1, limit = 20, listType = chatroomListType.UNREAD } = {}) =>
  async dispatch => {
    if (!debounceFunction) {
      // Use debounce + throttle for fetch unread messages,
      // since action might be trigger from BottomNavigation and Chatrooms at same time
      debounceFunction = debounce(
        throttle(
          ({ page, limit, listType }) =>
            dispatch(fetchChatroomsCore({ page, limit, listType })),
          API_THROTTLE_SECONDS * 1000
        ),
        UNREAD_DEBOUNCE_MSEC
      );
    }
    return debounceFunction({ page, limit, listType });
  };

export const fetchPinnedChatrooms =
  ({ page = 1, limit = 20, listType = chatroomListType.PIN } = {}) =>
  async dispatch => {
    if (!throttleFunctionMap[listType]) {
      throttleFunctionMap[listType] = throttle(
        ({ page, limit, listType }) =>
          dispatch(fetchChatroomsCore({ page, limit, listType })),
        API_THROTTLE_SECONDS * 1000
      );
    }
    return throttleFunctionMap[listType]({ page, limit, listType });
  };

export const fetchChatroomsCore =
  ({ page = 1, limit = 20, listType = chatroomListType.ALL }) =>
  async (dispatch, getState) => {
    const token = getMeData(getState(), 'token');
    const meId = getMeData(getState(), 'id');

    if (!token) {
      return dispatch({ type: '' });
    }

    const selectPath = ['chatrooms', listType, page];
    const listSelectPath = ['chatrooms', listType];
    const apiCacheTimestamp = getOperationData(
      getState(),
      ['chat'],
      'apiCacheTimestamp'
    );

    const fetchOptions = {
      headers: {
        ...getHeaders(),
        Authorization: `Bearer ${token}`,
      },
    };
    const url = getResourceUrl({ endpoint: '/chat' });

    url.searchParams.append('page', page);
    url.searchParams.append('limit', limit);
    url.searchParams.append('_', apiCacheTimestamp);

    if (listType === chatroomListType.UNREAD) {
      url.searchParams.append('read', 0);
    } else if (listType === chatroomListType.READ) {
      url.searchParams.append('read', 1);
    }

    dispatch({ type: SET_NETWORKING_FETCHING, payload: { selectPath } });

    try {
      let response = await fetch(url.href, fetchOptions);

      if (!response.ok) {
        response = await handleFetchError({
          response,
          dispatch,
          getState,
          fetchOptions,
          fetchUrl: url,
        });
      }

      const totalCount = parseInt(response.headers.get('x-total-count'), 10);
      const links = parseLinkHeader(response.headers.get('Link'));
      const nextPage = links && links.next && parseInt(links.next.page, 10);
      const lastPage = links && links.last && parseInt(links.last.page, 10);

      const data = await response.json();
      const formattedData = data.reduce(
        (acc, item) => {
          const chatroom = {
            id: item.id,
            hasUnread: item.hasUnread,
            unreadCount: item.unreadCount,
            unreadGiftCount: item.unreadGiftCount,
            lastMessageId: item.lastMessage.id,
            participantIds: item.participants.map(user => user.id),
            pinned: item.pinned,
          };
          const {
            lastMessage: { media, sender, postedAt, ...lastMessageData },
            participants: users,
          } = item;
          const message = {
            ...lastMessageData,
            senderId: sender,
            postedAtUnix: postedAt,
          };
          return {
            chatrooms: acc.chatrooms.concat(chatroom),
            users: acc.users
              .filter(u => !chatroom.participantIds.includes(u._id))
              .concat(users.map(({ username, ...other }) => other)),
            messages: acc.messages
              .filter(m => message.id !== m.id)
              .concat(message),
            unreadCount: acc.unreadCount + item.unreadCount,
          };
        },
        { chatrooms: [], messages: [], users: [], unreadCount: 0 }
      );
      const chatrooms = objectifyArrayById({
        array: formattedData.chatrooms,
      });

      const messages = objectifyArrayById({ array: formattedData.messages });
      const users = objectifyArrayById({ array: formattedData.users });
      const itemIds = Object.keys(chatrooms);

      dispatch({ type: ADD_USERS, payload: { users } });
      dispatch({ type: ADD_MESSAGES, payload: { messages } });
      dispatch({ type: ADD_CHATROOMS, payload: { chatrooms } });

      if (page === 1 && formattedData.unreadCount > 0) {
        dispatch({
          type: MERGE_ME_DATA,
          payload: {
            chatroom: {
              unreadCount: formattedData.unreadCount,
            },
          },
        });
      }

      const listActionType = page === 1 ? SET_LIST_ITEMS : ADD_LIST_ITEMS;
      if (listType === chatroomListType.PIN) {
        const pinnedChatroomIds = itemIds.filter(id => {
          const isPinned = !!getChatroomData(getState(), id, 'pinned');
          return isPinned;
        });
        const pinnedNextPage =
          pinnedChatroomIds.length &&
          pinnedChatroomIds.length !== totalCount &&
          pinnedChatroomIds.length === itemIds.length
            ? page + 1
            : null;
        dispatch({
          type: listActionType,
          payload: {
            selectPath: listSelectPath,
            itemIds: pinnedChatroomIds,
            nextPage: pinnedNextPage,
          },
        });
      } else {
        dispatch({
          type: listActionType,
          payload: {
            selectPath: listSelectPath,
            itemIds,
            totalCount,
            nextPage,
            lastPage,
          },
        });
      }

      formattedData.users.forEach(u => {
        u.id !== meId ? dispatch(fetchUser({ id: u.id })) : null;
      });

      return dispatch({
        type: SET_NETWORKING_SUCCESS,
        payload: { selectPath },
      });
    } catch (error) {
      return dispatch({
        type: SET_NETWORKING_ERROR,
        payload: { selectPath, error },
      });
    }
  };

export default fetchChatrooms;
